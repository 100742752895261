<template>
  <div>
    <h2>Loading...</h2>
  </div>
</template>

<script>
import APIService from "@/api/api";

export default {
  name: "CallbackPage",
  mounted() {
    const fragment = window.location.hash.substring(1);

    const params = new URLSearchParams(fragment);

    const accessToken = params.get("access_token");

    if (accessToken) {
      localStorage.setItem("access_token", accessToken);
      this.instagramLoginUrl();
    } else {
      // Extract code from the query string
      const code = new URLSearchParams(window.location.search).get("code");
      const query = this.$route.query;

      if (code) {
        // If code exists, fetch the access token using it
        this.fetchAccessToken(code);
      } else {
        if (query.error) {
          this.instagramLoginUrl();
        } else {
          // If neither access_token nor code are available, show an error
          alert("Authorization failed");
          this.$router.push({ name: "Login" });
        }
      }
    }
  },

  methods: {
    async instagramLoginUrl() {
      try {
        const res = await new APIService().api({
          method: "get",
          url: "auth/instagram/auth"
        });

        if (res?.instagramAuthUrl) {
          window.location.href = res.instagramAuthUrl;
        } else if (res?.error?.message) {
          alert(`Error: ${res.error.message}`);
        }
      } catch (error) {
        console.error(
          "An error occurred during Google sign-in initiation:",
          error
        );
        alert("An unexpected error occurred. Please try again.");
      }
    },
    async fetchAccessToken(code) {
      try {
        try {
          const res = await new APIService().api(
            {
              method: "get",
              url: "auth/instagram/callback"
            },
            { code }
          );
          console.log("res", res);
          if (res.accessToken) {
            const { accessToken, profile } = res;
            localStorage.setItem("instagram_access_token", accessToken);
            localStorage.setItem("instagram_user", JSON.stringify(profile));
            this.$router.push("/profile");
          }
        } catch (error) {
          console.error(
            "An error occurred during Google sign-in initiation:",
            error
          );
          alert("An unexpected error occurred. Please try again.");
        }
      } catch (error) {
        console.error("Error fetching access token", error);
      }
    }
  }
};
</script>
