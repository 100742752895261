<template>
  <div>
    <h1>Data Deletion Request</h1>
    <p>
      If you would like to delete your data, please confirm your request below.
    </p>

    <label for="email">Your Email:</label>
    <input type="email" id="email" name="email" required />
    <button type="submit">Request Data Deletion</button>
  </div>
</template>

<script>
export default {
  name: "DeletionRequest"
};
</script>
