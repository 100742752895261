<template>
  <div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>
      **Data Collection** We collect the following information from you when you
      use our Instagram platform: * Your name * Your email address * Your
      profile picture * Your posts and comments * Your interactions with other
      users
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>

<style scoped>
/* Styles for the privacy policy page */
</style>
