<template>
  <div class="login">
    <h1>Instagram Login</h1>
    <button @click="instagramLoginUrl">Login with Instagram</button>
  </div>
</template>

<script>
import APIService from "@/api/api";
export default {
  name: "loginPage",
  methods: {
    async instagramLoginUrl() {
      try {
        const res = await new APIService().api({
          method: "get",
          url: "auth/instagram"
        });

        if (res?.instagramAuthUrl) {
          window.location.href = res.instagramAuthUrl;
        } else if (res?.error?.message) {
          alert(`Error: ${res.error.message}`);
        }
      } catch (error) {
        console.error(
          "An error occurred during Google sign-in initiation:",
          error
        );
        alert("An unexpected error occurred. Please try again.");
      }
    }
  }
};
</script>

<style scoped>
.login {
  text-align: center;
}
</style>
