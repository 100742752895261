<template>
  <div>
    <h2>Your Instagram Profile</h2>
    <div class="d-flex">
      <div class="w-20" v-if="user">
        <img
          v-if="user.profile_picture_url"
          :src="user.profile_picture_url"
          alt="Profile Picture"
        />
        <p>Username: {{ user.username }}</p>
        <p>Full Name: {{ user.name }}</p>
        <p>Account Type: {{ user.account_type }}</p>
        <p>Followers Count: {{ user.followers_count }}</p>
        <p>Follows Count: {{ user.follows_count }}</p>
        <p>Media Count: {{ user.media_count }}</p>
        <p v-if="user.totalLikes">Like Count: {{ user.totalLikes }}</p>
      </div>
      <div v-else>
        <p>Loading...</p>
      </div>
      <div class="w-40" v-if="media">
        <div v-for="m in media" :key="m.id" class="media-block">
          <p>Media Type: {{ m.media_type }}</p>
          <img
            v-if="m.media_type === 'IMAGE' || m.media_type == 'CAROUSEL_ALBUM'"
            :src="m.media_url"
            alt="Instagram Image"
            class="media-img"
          />

          <!-- Display video if media type is VIDEO -->
          <video v-if="m.media_type === 'VIDEO'" controls class="media-video">
            <source :src="m.media_url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <P>{{ m.caption }}</P>

          <p>Like Count: {{ m.like_count }}</p>
          <p class="mb-1">Time: {{ m.timestamp }}</p>
          <button type="button" @click="getPostDetails(m.id, m.media_type, m)">
            Get Post Details
          </button>
        </div>
      </div>
      <div ref="postDetails" class="w-40 pl-1">
        <div class="media-block">
          <div v-if="isLoading">
            <p>Loading...</p>
          </div>
          <div v-else-if="isError && currentMedia">
            <p>Media Type: {{ currentMedia.media_type }}</p>
            <P>Media Caption {{ currentMedia.caption }}</P>
            <p>Likes: {{ currentMedia.like_count }}</p>
            <p class="mb-1">Time: {{ currentMedia.timestamp }}</p>

            <a :href="currentMedia.permalink" target="_blank"
              >View on Instagram</a
            >
          </div>
          <div v-else-if="shares || views || like_count">
            <p>Impressions: {{ impressions }}</p>
            <p>Shares: {{ shares }}</p>
            <p>Views: {{ views }}</p>
            <p>Replays: {{ replays }}</p>
            <p>Initial Plays: {{ plays }}</p>
            <p>Total Plays: {{ total_plays }}</p>
            <p>Average Watch Time: {{ avg_watch_time }} ms</p>
            <p>Total View Time: {{ video_view_total_time }} ms</p>
            <p>Comments: {{ comment }}</p>
            <p>Likes: {{ like_count }}</p>
            <p>Reach: {{ reach }}</p>
            <p>Saved: {{ saved }}</p>
            <p>Follows: {{ follows }}</p>
            <p>Profile Activity: {{ profile_activity }}</p>
            <p>Profile Visits: {{ profile_visits }}</p>
            <p>Total Interactions: {{ total_interactions }}</p>
            <a :href="currentMedia.permalink" target="_blank"
              >View on Instagram</a
            >
          </div>
          <div v-else>
            <p>Data Not Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/api/api";

export default {
  name: "ProfilePage",
  data() {
    return {
      user: null,
      media: [],
      shares: 0,
      views: 0,
      comment: 0,
      like_count: 0,
      plays: 0,
      replays: 0,
      total_plays: 0,
      avg_watch_time: 0,
      video_view_total_time: 0,
      reach: 0,
      saved: 0,
      total_interactions: 0,
      impressions: 0,
      follows: 0,
      profile_activity: 0,
      profile_visits: 0,
      isShowPostDetails: false,
      isLoading: false,
      isError: false,
      currentMedia: null
    };
  },
  async mounted() {
    const accessToken = localStorage.getItem("instagram_access_token");
    const user = localStorage.getItem("instagram_user");

    if (accessToken && user) {
      this.user = JSON.parse(user);
      this.fetchUserMedia();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    async fetchUserMedia() {
      try {
        const res = await new APIService().api(
          {
            method: "get",
            url: "auth/instagram/media"
          },
          {}
        );
        if (res && res.media?.length) {
          if (this.user) {
            this.user.totalLikes = res.totalLikes;
          }
          this.media = res.media;
        }
      } catch (error) {
        console.error("Error fetching access token", error);
      }
    },
    clearValues() {
      this.shares = 0;
      this.views = 0;
      this.comment = 0;
      this.like_count = 0;
      this.plays = 0;
      this.replays = 0;
      this.total_plays = 0;
      this.avg_watch_time = 0;
      this.video_view_total_time = 0;
      this.reach = 0;
      this.saved = 0;
      this.total_interactions = 0;
      this.impressions = 0;
      this.follows = 0;
      this.profile_activity = 0;
      this.profile_visits = 0;
      this.isError = false;
    },
    async getPostDetails(id, media_type, media) {
      try {
        this.currentMedia = media;
        this.clearValues();
        const res = await new APIService().api(
          {
            method: "get",
            url: `auth/instagram/media-by-id?id=${id}&media_type=${media_type}`
          },
          {},
          { isPost: true }
        );
        if (res && res.data?.length) {
          res.data.forEach((item) => {
            switch (item.name) {
              case "shares":
                this.shares = item.values[0].value;
                break;
              case "video_views":
                this.views = item.values[0].value;
                break;
              case "clips_replays_count":
                this.replays = item.values[0].value;
                break;
              case "plays":
                this.plays = item.values[0].value;
                break;
              case "ig_reels_aggregated_all_plays_count":
                this.total_plays = item.values[0].value;
                break;
              case "ig_reels_avg_watch_time":
                this.avg_watch_time = item.values[0].value;
                break;
              case "ig_reels_video_view_total_time":
                this.video_view_total_time = item.values[0].value;
                break;
              case "comments":
                this.comment = item.values[0].value;
                break;
              case "likes":
                this.like_count = item.values[0].value;
                break;
              case "reach":
                this.reach = item.values[0].value;
                break;
              case "saved":
                this.saved = item.values[0].value;
                break;
              case "total_interactions":
                this.total_interactions = item.values[0].value;
                break;
              case "impressions":
                this.impressions = item.values[0].value;
                break;
              case "profile_activity":
                this.profile_activity = item.values[0].value;
                break;
              case "profile_visits":
                this.profile_visits = item.values[0].value;
                break;
              case "follows":
                this.follows = item.values[0].value;
                break;
              default:
                break;
            }
          });
          this.$nextTick(() => {
            this.$refs.postDetails.scrollIntoView({ behavior: "smooth" });
          });
        } else if (res?.error) {
          alert(res?.error, res?.message);
          this.isError = true;
          this.$nextTick(() => {
            this.$refs.postDetails.scrollIntoView({ behavior: "smooth" });
          });
        } else if (res.message) {
          this.isError = true;
          this.$nextTick(() => {
            this.$refs.postDetails.scrollIntoView({ behavior: "smooth" });
          });
        }
      } catch (error) {
        console.error("Error fetching access token", error);
      }
    }
  }
};
</script>

<style scoped>
.d-flex {
  display: flex;
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-20 {
  width: 20%;
}
.w-40 {
  width: 40%;
}
.mb-1 {
  margin-bottom: 8px;
}
.pl-1 {
  padding-left: 8px;
}
.media-block {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.media-img {
  width: 100%;
  height: auto;
  margin-bottom: 8px;
}
.media-video {
  width: 100%;
  height: auto;
  /* height: 400px; */
  margin-bottom: 8px;
}
</style>
