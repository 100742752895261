import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/Login.vue";
import Callback from "@/views/Callback.vue";
import Profile from "@/views/Profile.vue";
import NotFound from "@/views/NotFound.vue";
import DeletionRequest from "@/views/DeletionRequest.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/callback",
    name: "Callback",
    component: Callback
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/deauthorize",
    name: "Deauthorize",
    component: Callback
  },
  {
    path: "/deletion-request",
    name: "DeletionRequest",
    component: DeletionRequest
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
