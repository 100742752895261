import axios from "axios";
import Cookies from "js-cookie";

export default class APIService {
  async api(
    { url, method },
    data = {},
    options = {
      show_loader: true,
      http_headers: {},
      api_version: undefined,
      isLoading: false,
      isPost: false
    }
  ) {
    try {
      const headers = {
        "Content-Type": "application/json"
      };
      // const token = Cookies.get("token");
      const token = localStorage.getItem("instagram_access_token");
      const token1 = localStorage.getItem("access_token");
      if (options.isPost) {
        headers.authorization = `Bearer ${token1}`;
      } else if (token) {
        headers.authorization = `Bearer ${token}`;
      }

      const config = {
        method,
        // url: process.env.VUE_APP_API + url,
        url: "/api/" + url,
        headers: {
          ...headers,
          ...options.http_headers
        }
      };

      if (method?.toUpperCase() === "GET" && data) {
        config.params = data;
      } else {
        config.data = data;
      }

      const res = await axios(config);

      if (res?.data?.errors?.length) {
        const error = res.data.errors[0];
        if (error && ["553", "701", "554"].includes(error.code)) {
          this.logout();
        } else {
          return { error: { ...error } };
        }
      }

      return res?.data || res;
    } catch (err) {
      return this.handleError(err);
    }
  }

  handleError(err) {
    if (
      err.response?.data?.errors?.length &&
      err.response.data.errors[0].message
    ) {
      return { error: { ...err.response.data.errors[0] } };
    }

    if (err.response?.status === 401) {
      this.logout();
      // Optionally redirect to login page
    }

    return (
      err.response?.data || {
        error: { header: { status: "ERROR" }, message: "Something went wrong" }
      }
    );
  }

  logout() {
    Cookies.remove("token");
    // Optionally redirect to login page
  }
}
