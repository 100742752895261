<template>
  <div>
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for doesn't exist.</p>
    <router-link to="/">Go back to home</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>
